import React, { useRef, useState, useEffect } from "react";
import Layout from "../Layout";
import { Link } from "react-router-dom";
// import getStarted from "./imgs/get_started_btn.webp";
import CookieConsent from "react-cookie-consent";
import sf_demo from "../imgs/sf_demo.mp4";
import ad_vid_website from "../imgs/ad_vid_website.mp4";
import zombie from "../imgs/zombie.webm";
import avatar from "../imgs/avatar_demo.webm";
import Grid from "@mui/material/Unstable_Grid2";
import hero_bg from "../imgs/bg_round_shapes_new.svg";
import coinImg from "../imgs/coins.webp";
import thunderImg from "../imgs/thunder.webp";
import maskImg from "../imgs/mask.webp";
import { Marque } from "../Brands/brands";

function Modal({ isOpen, onClose, children }) {
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add("body-no-scroll");
    } else {
      body.classList.remove("body-no-scroll");
    }

    // Clean up function
    return () => {
      body.classList.remove("body-no-scroll");
    };
  }, [isOpen]); // Only re-run the effect if isOpen changes

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center", // This helps center vertically
        justifyContent: "center", // This helps center horizontally
        zIndex: 10,
      }}
    >
      <div
        style={{
          width: "80%", // Uses 80% of the viewport width
          height: "80%", // Makes sure it doesn't overflow vertically
          overflowY: "auto", // Adds scroll to the modal content if it overflows vertically
          background: "white",
          padding: "20px",
          boxSizing: "border-box", // Ensures padding is included in the width
          position: "relative", // For the close button positioning
          zIndex: 11,
        }}
        className="modal-content"
      >
        {children}
        <button
          onClick={onClose}
          style={{ position: "absolute", top: 10, right: 10, zIndex: 12 }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function BookingButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    window.gtag("event", "conversion_event_book_appointment");
  };
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Link to="#" onClick={openModal}>
        <span className="launch_app">Book a Meeting</span>
      </Link>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <iframe
          title="Book Appointment"
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1vN6o0jIfLF9L6mW_B2AiwiR-LLNmXnQ8UJM99X59C9LcXeBdpvsMmvYnMGz3aQ1SGBag-U6uK?gv=true"
          style={{ border: 0 }}
          width="100%"
          height="100%"
        ></iframe>
      </Modal>
    </>
  );
}

function LandingPage() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const featuresRef = useRef(null);

  return (
    <Layout>
      <section className="new-hero-section agencies">
        <img src={hero_bg} alt="hero-bg" className="background_image" />

        <div className="container-fluid">
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            sx={{ position: "relative", zIndex: "6" }}
          >
            <Grid item md={6}>
              <div style={{ flex: 1, padding: 20 }}>
                <h1 className="hero_title" style={{ lineHeight: 1.3 }}>
                  Turn Influencer Marketing into <span>AR</span> Journeys
                </h1>
                <p className="mt-4">
                  Our platform allows seamless AR Integration for your
                  Campaigns. Our art studio makes your vision into (augmented)
                  reality.
                </p>
                <p>
                  Make your brand stand out, your creators have fun and engage
                  with your audience in a new dimension - literally.
                </p>
                <div
                  style={{
                    display: "flex",
                    marginTop: "40px",
                  }}
                >
                  <BookingButton />
                  <div
                    class="center-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 0 /* Removes default margin */,
                    }}
                  >
                    <a
                      href="mailto:kev@streamfog.com"
                      style={{ textDecoration: "none" }}
                    >
                      <button style={{ marginLeft: "40px" }}>Contact us</button>
                    </a>
                  </div>
                  <Modal></Modal>
                </div>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ flex: 2 }}>
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  // playsInline={true}
                  playsInline
                  preload="auto"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                  style={{ width: "100%" }}
                >
                  <source src={ad_vid_website} type="video/webm" />
                </video>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      {/* <!--Just for Desktop Separate Section--> */}
      <section className="section_features_main" ref={featuresRef}>
        <div className="container-fluid">
          <img src={coinImg} className="coin-img" alt="" />
          <img src={thunderImg} className="thunder-img" alt="" />
          <img src={maskImg} className="mask-img" alt="" />
          <h6
            className="section-title"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            What to expect
          </h6>

          <Grid container spacing={6}>
            <Grid item md={4}>
              <p className="feature-title">Stunning visuals</p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  // playsInline={true}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={sf_demo} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Make your brand and products shine in 3D. Amazed viewers instead
                of disrupted audience.
              </span>
            </Grid>
            <Grid item md={4}>
              <p className="feature-title">Engaged viewers</p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={avatar} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Twitch campaigns revolve around interactivity, otherwise you
                would use Google Ads. Let's emphasize that. Interactive
                campaigns, engaged viewers.
              </span>
            </Grid>
            <Grid item md={4}>
              <p className="feature-title">Lasting campaigns</p>
              <div className="feature_video-wrapper">
                <video
                  autoPlay="autoplay"
                  loop
                  muted
                  controls={window.innerWidth < 767 ? "controls" : false}
                  playsInline
                  preload="auto"
                  className="feature-video green-shadow"
                  width="100%"
                  webkit-playsinline="true"
                  x-webkit-airplay="true"
                >
                  <source src={zombie} type="video/webm" />
                </video>
              </div>
              <span className="feature-decs">
                Add your brand assets to the marketplace for all streamers to
                play around with. Extend your campaign life cycle.
              </span>
            </Grid>
          </Grid>
        </div>
      </section>
      <section
        className="new-hero-section"
        style={{ minHeight: "80vh", marginTop: "-3%" }}
      >
        <img
          src={hero_bg}
          alt="hero-bg"
          className="background_image brand_backimg"
        />
        <div className="new-hero-wrapper mobile_brands">
          {" "}
          <h4 className="highlighted">TRUSTED BY : </h4>
          <Marque />
        </div>
      </section>
      <CookieConsent
        enableDeclineButton
        flipButtons
        style={{
          background: "#171d21",
          display: "block",
          textAlign: "center",
          maxWidth: 300,
          padding: "10px 10px 30px",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
          left: 20,
          marginBottom: 20,
          border: "1px solid #7cd959",
        }}
        buttonStyle={{
          background: "#7cd959",
          fontWeight: 700,
          margin: 0,
        }}
        declineButtonStyle={{ fontWeight: 700, margin: 0, marginLeft: 20 }}
      >
        <h5
          style={{ opacity: 0.9, textTransform: "uppercase", color: "#7cd959" }}
        >
          We use cookies
        </h5>
        <p style={{ opacity: 0.9, marginBottom: 0 }}>
          Cookies help us deliver the best experience on our website. By using
          our website, you agree to the use of cookies.
        </p>
      </CookieConsent>
    </Layout>
  );
}

export default LandingPage;
