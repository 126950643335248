import React from "react";
import Layout from "../Layout";

function Legal() {
  return (
    <Layout>
      {" "}
      <div className="streamer_header">
        <h1>
          <b className="highlighted">Streamfog</b> Legal Notice
        </h1>
      </div>
      <div className="streamer_body">
      <div className="table_body">
        <h2 className="highlighted">Streamfog UG (haftungsbeschränkt)</h2>
        <p>Lothstrasse 13</p>
        <p>80335 Munich</p>
        <p>
          <b className="highlighted">
            <svg
              fill="#7cd959"
              width="20"
              height="20"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 75.294 75.294"
              xmlSpace="preserve"
              stroke="#7cd959"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0" />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                <g>
                  <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9 c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089 H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065 c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016 c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102 c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069 c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"></path>
                </g>
              </g>
            </svg>
            :
          </b>
          kev@streamfog.com
        </p>
        <p>
          <b className="highlighted">
            <svg
              style={{ color: "#7cd959" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                fill="#35f341"
              ></path>
            </svg>
            :
          </b>
          +49 (0) 176 56617878
        </p>
        <h3 className="highlighted">Represented by:</h3>
        <p>Kevin Bondzio (CEO)</p>
        <h3 className="highlighted">Register Entry:</h3>
        <p>Entered in the commercial register.</p>
        <h3 className="highlighted">Register court:</h3> Munich
        <h3 className="highlighted">Register number:</h3> HRB 286117
        <h3 className="highlighted">Sales tax ID:</h3>
        <p>
          Sales tax identification number according to §27a sales tax law:
          Requested
        </p>
        <h3 className="highlighted">
          Responsible for the content (according to § 55 Abs. 2 RStV):
        </h3>
        <p>
          Kevin Bondzio Streamfog UG (haftungsbeschränkt) Lothstraße 13b 80335
          Munich
        </p>
        <p>Note according to the Consumer Dispute Settlement Act (VSBG)</p>
        <p>
          We are neither willing nor obliged to take part in dispute settlement
          proceedings before a consumer arbitration board.
        </p>
      </div>
    </div>
    </Layout>
  );
}

export default Legal;
